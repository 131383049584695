*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: African;
  src: url("BurowaiRegular-3zVjX.5b8c9e45.ttf");
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
}

@media only screen and (width <= 75em) {
  html {
    font-size: 50%;
  }
}

body {
  color: #000;
  background-color: #ffc82f;
  font-family: Muli, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.container {
  grid-template-rows: 90vh min-content min-content min-content min-content;
  grid-template-columns: [sidebar-start] 8rem[sidebar-end full-start] minmax(6rem, 1fr)[center-start] repeat(8, [col-start] minmax(min-content, 14rem)[col-end])[center-end] 8rem[full-end];
  display: grid;
  overflow: hidden !important;
}

@media only screen and (width <= 56em) {
  .container {
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-columns: [sidebar-start] 3rem[sidebar-end full-start] minmax(6rem, 1fr)[center-start] repeat(8, [col-start] minmax(min-content, 14rem)[col-end])[center-end] 3rem[full-end];
  }
}

@media only screen and (width <= 40.6em) {
  .container {
    grid-template-rows: min-content min-content min-content min-content min-content;
    grid-template-columns: [sidebar-start] 1rem[sidebar-end full-start] minmax(6rem, 1fr)[center-start] repeat(8, [col-start] minmax(min-content, 14rem)[col-end])[center-end] 1rem[full-end];
  }
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.mt-m-4 {
  margin-top: -4rem;
}

.ml-m-11 {
  margin-left: -11rem;
}

.color-pr {
  background-color: #003eff !important;
}

.btn {
  color: #fff;
  letter-spacing: .2rem;
  padding: 1.4rem 3.2rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (width <= 56em) {
  .btn {
    padding: 1.5rem 2rem;
  }
}

.btn__footer {
  background: #000;
  border-radius: 0 1rem 0 0;
  transition: box-shadow .5s;
}

.btn__footer:hover, .btn__footer:active {
  box-shadow: -6px 5px 7px 2px #0003;
}

@media only screen and (width <= 31.25em) {
  .btn__footer {
    border-radius: 0;
    padding: 1.5rem 9rem;
  }
}

.btn--nav {
  border-radius: 0 1rem 0 0;
}

@media only screen and (width <= 31.25em) {
  .btn--nav {
    visibility: hidden;
  }
}

.btn--plain {
  color: #003eff;
  background-image: linear-gradient(120deg, #0000 0 50%, #fff 50%);
  background-size: 220%;
  margin-left: 1.5rem;
  transition: all .3s ease-in;
}

.btn--plain:hover, .btn--plain:active {
  background-position: 100%;
  box-shadow: -6px 5px 7px 2px #0003;
}

.btn__cta {
  background: #003eff;
  transition: all .3s ease-in !important;
}

.btn__cta:hover, .btn__cta:active {
  color: #003eff;
  background-color: #fff;
  box-shadow: -6px 5px 7px 2px #0003;
}

.btn__social {
  background-color: #003eff;
  border-radius: 2.5px;
  padding: 1.5rem 2.8rem;
  position: relative;
}

.btn__social--google {
  background-color: #003eff;
}

.btn__social--google img {
  width: 23%;
  position: absolute;
  top: 3.5px;
  left: 5px;
}

.btn__social--google span {
  margin-left: 3rem;
}

.btn__social--whatsapp {
  background-color: #098537;
}

.btn__social--whatsapp img {
  width: 16%;
  position: absolute;
  top: 9px;
  left: 12px;
}

.btn__social--whatsapp span {
  margin-left: 1.5rem;
}

.heading-1 {
  color: #fff;
  font-size: 4rem;
}

.heading-1--footer {
  font-family: African, sans-serif;
}

.heading-2 {
  font-size: 3.5rem;
}

@media only screen and (width <= 31.25em) {
  .heading-2 {
    font-size: 2.5rem;
  }
}

.heading-2--white {
  color: #fff;
}

.heading-4 {
  font-size: 1.5rem;
}

.heading-4--nowrap {
  white-space: nowrap;
}

.heading-4 span {
  color: #fff;
  background: #003eff;
  padding: .5rem 0;
}

.text-box__heading {
  align-items: center;
  display: flex;
}

.line {
  background: #003eff;
  width: 15rem;
  height: .2rem;
  margin-left: 5px;
}

@media only screen and (width <= 31.25em) {
  .line {
    width: 13rem;
  }
}

.header {
  grid-column: full-start / center-end;
  grid-template-rows: min-content min-content;
  display: grid;
}

@media only screen and (width <= 56em) {
  .header {
    gap: 5rem;
  }
}

.header .hero-section {
  grid-template-columns: 1fr minmax(min-content, 1fr);
  align-items: center;
  display: grid;
}

@media only screen and (width <= 31.25em) {
  .header .hero-section {
    grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr);
    grid-template-columns: auto;
  }
}

.header .hero-section__content {
  flex-direction: column;
  gap: 1rem 0;
  display: flex;
}

@media only screen and (width <= 31.25em) {
  .header .hero-section__content {
    align-items: center;
  }
}

.header .hero-section__title {
  font-size: 5rem;
  line-height: 1.25;
}

@media only screen and (width <= 56em) {
  .header .hero-section__title {
    font-size: 4rem;
  }
}

@media only screen and (width <= 31.25em) {
  .header .hero-section__title {
    text-align: center;
  }
}

.header .hero-section__title span {
  color: #fff;
  background-color: #003eff;
}

.header .hero-section__image img {
  width: 100%;
  height: 100%;
}

.header .hero-section__paragraph {
  letter-spacing: .5px;
  color: #000;
  text-align: left;
  width: 57%;
  margin-bottom: 1.2rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
}

@media only screen and (width <= 56em) {
  .header .hero-section__paragraph {
    text-align: center;
    width: 66%;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  margin-top: 3.2rem;
  display: flex;
}

@media only screen and (width <= 40.6em) {
  .nav {
    overflow: hidden;
  }
}

.nav__logo {
  color: #000;
  white-space: nowrap;
  font-family: African, sans-serif;
  font-size: 3.9rem;
  text-decoration: none;
}

@media only screen and (width <= 56em) {
  .nav__logo {
    font-size: 3.3rem;
  }
}

.nav__list {
  align-items: center;
  gap: 5rem;
  list-style-type: none;
  display: flex;
}

@media only screen and (width <= 56em) {
  .nav__list {
    gap: 2rem;
  }
}

@media only screen and (width <= 31.25em) {
  .nav__list {
    visibility: hidden;
  }
}

.nav__list .nav__link {
  color: #000;
  background-image: linear-gradient(90deg, #0000 0 50%, #003eff 50%);
  background-size: 220%;
  padding: .7rem;
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  transition: all .25s ease-in-out;
}

@media only screen and (width <= 56em) {
  .nav__list .nav__link {
    font-size: 1.35rem;
  }
}

.nav__list .nav__link:hover, .nav__list .nav__link:active {
  color: #fff;
  background-position: 100%;
}

@media only screen and (width >= 31.25em) {
  .mobile__navigation {
    visibility: hidden;
  }
}

.mobile__navigation__checkbox {
  display: none;
}

.mobile__navigation__button {
  z-index: 2000;
  text-align: center;
  cursor: pointer;
  background-color: #003eff;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  position: fixed;
  top: 4rem;
  right: 4rem;
  box-shadow: 0 1rem 3rem #0000001a;
}

.mobile__navigation__background {
  z-index: 1000;
  will-change: transform;
  background-image: radial-gradient(#ffc82f, #ffc82f);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  transition: transform .8s cubic-bezier(.86, 0, .07, 1);
  position: fixed;
  top: 4.5rem;
  right: 4.5rem;
}

.mobile__navigation__nav {
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  will-change: visibility, width, opacity;
  width: 0;
  height: 100vh;
  transition: all .8s cubic-bezier(.68, -.55, .265, 1.55);
  position: fixed;
  top: 0;
  left: 0;
}

.mobile__navigation__list {
  text-align: center;
  width: 100%;
  list-style-type: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile__navigation__item {
  margin: 1rem;
}

.mobile__navigation__link:link, .mobile__navigation__link:visited {
  color: #000;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, #0000 0% 50%, #fff 50%);
  background-size: 220%;
  padding: 1rem 2rem;
  font-size: 3rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .4s;
  display: inline-block;
}

.mobile__navigation__link:link span, .mobile__navigation__link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}

.mobile__navigation__link:hover, .mobile__navigation__link:active {
  color: #003eff;
  background-position: 100%;
  transform: translateX(1rem);
}

.mobile__navigation__checkbox:checked ~ .mobile__navigation__background {
  transform: scale(80);
}

.mobile__navigation__checkbox:checked ~ .mobile__navigation__nav {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.mobile__navigation__icon {
  margin-top: 3.5rem;
  position: relative;
}

.mobile__navigation__icon, .mobile__navigation__icon:before, .mobile__navigation__icon:after {
  background-color: #fff;
  width: 3rem;
  height: 2px;
  display: inline-block;
}

.mobile__navigation__icon:before, .mobile__navigation__icon:after {
  content: "";
  transition: all .2s;
  position: absolute;
  left: 0;
}

.mobile__navigation__icon:before {
  top: -.8rem;
}

.mobile__navigation__icon:after {
  top: .8rem;
}

.mobile__navigation__button:hover .mobile__navigation__icon:before {
  top: -1rem;
}

.mobile__navigation__button:hover .mobile__navigation__icon:after {
  top: 1rem;
}

.mobile__navigation__checkbox:checked + .mobile__navigation__button .mobile__navigation__icon {
  background-color: #0000;
}

.mobile__navigation__checkbox:checked + .mobile__navigation__button .mobile__navigation__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.mobile__navigation__checkbox:checked + .mobile__navigation__button .mobile__navigation__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

.mission {
  background: #003eff radial-gradient(circle, #003eff, #003df9, #003cf3, #013aed, #0139e7);
  border-radius: 1rem;
  grid-column: full-start / center-end;
  margin: 15rem 0;
  padding: 6.5rem 10rem;
  position: relative;
}

.mission img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -23%;
  left: -3%;
}

.mission__content {
  z-index: 10;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.mission__paragraph {
  text-align: center;
  color: #fff;
  width: 48%;
  font-size: 1.35rem;
  font-weight: 600;
  line-height: 1.7;
}

@media only screen and (width <= 56em) {
  .mission__paragraph {
    width: 90%;
    font-size: 1.5rem;
  }
}

@media only screen and (width <= 31.25em) {
  .mission__paragraph {
    text-align: justify;
    width: 100%;
    font-size: 1.3rem;
  }
}

.members {
  grid-column: full-start / center-end;
  margin: 15rem 0;
}

.members__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.members__group {
  scroll-behavior: smooth;
  column-gap: 3.5rem;
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none !important;
}

.members__group::-webkit-scrollbar {
  display: none;
}

.members__box__desc {
  margin-top: .5rem;
  font-size: 1.35rem;
}

.members__box__img {
  object-fit: cover;
  object-position: center;
  width: 22rem;
  height: 30rem;
  margin-bottom: 1rem;
  box-shadow: 4px 6px 10px #0000007a;
}

.members__box__img img {
  width: 100%;
  height: 100%;
}

.arrows {
  gap: 1rem;
  display: flex;
}

.next, .prev {
  cursor: pointer;
  border: 2px solid #0297d3;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  display: flex;
}

@media only screen and (width <= 31.25em) {
  .next, .prev {
    visibility: hidden;
  }
}

.next img, .prev img {
  width: 2rem;
  height: 2rem;
}

.visible {
  visibility: visible !important;
}

.modal {
  z-index: 3000;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  visibility: hidden;
  background-color: #000c;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all .5s;
  display: flex;
  position: fixed;
  overflow: hidden;
}

@media only screen and (width <= 31.25em) {
  .modal {
    padding: 0 2rem;
  }
}

.modal__paragraph {
  color: #fff;
  text-align: center;
  z-index: 3500;
  width: 57%;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.7;
  position: relative;
  overflow: hidden;
}

@media only screen and (width <= 31.25em) {
  .modal__paragraph {
    text-align: justify;
    width: 100%;
    font-size: 1.5rem;
  }
}

.modal__image {
  object-fit: fill;
  border-radius: 100%;
  width: 9rem;
  height: 9rem;
}

.modal__buttons {
  gap: 1rem;
  display: flex;
}

@media only screen and (width <= 31.25em) {
  .modal__buttons {
    flex-direction: column;
    align-items: center;
  }
}

.what_we_do {
  grid-column: full-start / center-end;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
  margin: 15rem 0;
  display: grid;
}

@media only screen and (width <= 56em) {
  .what_we_do {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: none;
    gap: 5rem 0;
  }
}

.boxes {
  grid-column-gap: 3.5rem;
  grid-template-columns: repeat(4, calc(25% - 3.5rem));
  margin-right: -3.5rem;
  display: grid;
}

@media only screen and (width <= 56em) {
  .boxes {
    grid-row-gap: 1.5rem;
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: none;
    margin-right: 0;
    display: grid;
  }
}

.box {
  opacity: 0;
  background: #000;
  flex-direction: column;
  align-items: center;
  height: 50rem;
  padding: 2rem 0;
  transition: all 1s;
  display: flex;
}

@media (prefers-reduced-motion) {
  .box {
    transition: none;
  }
}

@media only screen and (width <= 56em) {
  .box {
    background: #000;
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
    height: 15rem;
    padding: 2rem 0;
    display: flex;
  }
}

.box img {
  width: 5rem;
  height: 5rem;
  margin-bottom: 6rem;
}

@media only screen and (width <= 56em) {
  .box img {
    margin-bottom: 0;
    margin-left: 6rem;
  }
}

.box__line {
  background: #707070;
  width: 9rem;
  height: .2rem;
  rotate: 90deg;
}

@media only screen and (width <= 56em) {
  .box__line {
    rotate: initial;
    width: 15rem;
  }
}

.box__text {
  color: #fff;
  text-transform: uppercase;
  writing-mode: tb;
  letter-spacing: 1.5px;
  margin-top: 6rem;
  font-size: 1.5rem;
  font-weight: 600;
}

@media only screen and (width <= 56em) {
  .box__text {
    writing-mode: horizontal-tb;
    margin-top: 0;
  }
}

.box:nth-child(2) {
  transition-delay: .2s;
}

.box:nth-child(3) {
  transition-delay: .4s;
}

.box:nth-child(4) {
  transition-delay: .6s;
}

.text-box__paragraph {
  color: #000;
  width: 84%;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}

@media only screen and (width <= 56em) {
  .text-box__paragraph {
    width: 100%;
    font-size: 1.8rem;
  }
}

.join {
  grid-column: full-start / center-end;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
  align-items: center;
  margin: 15rem 0;
  display: grid;
}

.join__buttons {
  flex-wrap: wrap;
  align-items: center;
  column-gap: 2rem;
  display: flex;
}

@media only screen and (width <= 56em) {
  .join__buttons {
    gap: 2rem;
  }

  .join {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: none;
    gap: 5rem;
  }
}

.join__img {
  width: 90%;
}

.gallery {
  grid-column: sidebar-start / span 11;
  margin: 15rem 0;
}

.gallery__groups {
  grid-gap: 2rem;
  grid-template-rows: repeat(12, 10vw);
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

@media only screen and (width <= 56em) {
  .gallery__groups {
    grid-gap: .5rem;
  }
}

@media only screen and (width <= 31.25em) {
  .gallery__groups {
    grid-gap: 0rem;
  }

  .gallery-res {
    margin-left: 3rem !important;
  }
}

.gallery__img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.gallery__item {
  opacity: 0;
  transition: all 2s;
}

@media (prefers-reduced-motion) {
  .gallery__item {
    transition: none;
  }
}

.gallery__item--1 {
  grid-area: 1 / 1 / span 2 / span 2;
}

.gallery__item--2 {
  grid-area: 1 / 3 / span 5 / span 2;
  transition-delay: .2s;
}

.gallery__item--3 {
  grid-area: 1 / 5 / span 3 / span 2;
  transition-delay: .3s;
}

.gallery__item--4 {
  grid-area: 3 / 1 / span 4 / span 2;
  transition-delay: .4s;
}

.gallery__item--5 {
  grid-area: 7 / 1 / span 2 / span 2;
  transition-delay: .5s;
}

.gallery__item--6 {
  grid-area: 6 / 3 / span 3 / span 2;
  transition-delay: .6s;
}

.gallery__item--7 {
  grid-area: 4 / 5 / span 2 / span 2;
  transition-delay: .7s;
}

.gallery__item--8 {
  grid-area: 6 / 5 / span 3 / span 2;
  transition-delay: .8s;
}

.gallery__item--9 {
  grid-area: 9 / 1 / span 3 / span 4;
  transition-delay: .9s;
}

.gallery__item--10 {
  grid-area: 9 / 5 / span 4 / span 2;
  transition-delay: 1s;
}

.show {
  opacity: 1;
}

.footer {
  color: #fff;
  background-color: #003eff;
  grid-column: sidebar-start / span 11;
  grid-template-columns: [sidebar-start] 8rem[sidebar-end full-start] minmax(6rem, 1fr)[center-start] repeat(8, [col-start] minmax(min-content, 14rem)[col-end])[center-end] 8rem[full-end];
  padding: 5rem;
  display: grid;
  position: relative;
}

@media only screen and (width <= 56em) {
  .footer {
    grid-template-columns: [sidebar-start] 1.5rem[sidebar-end full-start] minmax(6rem, 1fr)[center-start] repeat(8, [col-start] minmax(min-content, 14rem)[col-end])[center-end] 1.5rem[full-end];
  }
}

.footer__map {
  text-align: left;
  align-items: start;
  column-gap: .5rem;
  width: 68%;
  font-size: 1.3rem;
  display: flex;
}

@media only screen and (width <= 31.25em) {
  .footer__map {
    text-align: center;
    width: 100%;
  }

  .footer__logo {
    text-align: center;
  }
}

.footer__contents {
  flex-direction: column;
  grid-column: full-start / center-end;
  gap: 6rem;
  display: flex;
}

.footer__contents .copyright {
  text-align: center;
  font-size: 1.3rem;
}

.footer__nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (width <= 31.25em) {
  .footer__nav {
    flex-direction: column;
  }
}

.footer .nav__link {
  color: #f4f4f4;
  background-image: linear-gradient(90deg, #0000 0 50%, #000 50%);
}

.footer hr {
  color: #0000004f;
  width: 72%;
  margin: 0 auto;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.e06c621a.css.map */
