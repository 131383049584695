.what_we_do { 
    grid-column: full-start / center-end;
    display: grid;
    grid-template-columns: repeat(2,minmax(min-content, 1fr));
    margin: 15rem 0;

    @media only screen and (max-width: $bp-medium) {
        grid-template-rows: repeat(2,min-content);
        grid-template-columns: none;
        gap: 5rem 0;
     }
}


.boxes {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 3.5rem));
    grid-column-gap: 3.5rem;
    margin-right: -3.5rem;

    @media only screen and (max-width: $bp-medium) {
        grid-template-columns: none;
        grid-row-gap: 1.5rem;
        grid-template-rows: repeat(4, min-content);
        margin-right: 0;
        display: grid;
    }
}

.box {
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    height: 50rem;
    opacity: 0;
    transition: all 1s;

    @media(prefers-reduced-motion) {
        transition: none;
    }

 
    @media only screen and (max-width: $bp-medium) {
        height: 15rem;
        background: #000;
        flex-direction: row;
        align-items: center;
        padding: 2rem 0;
        display: flex;
        column-gap: 2rem;
     }
    
    img{
        height: 5rem;
        width: 5rem;
        margin-bottom: 6rem;

        @media only screen and (max-width: $bp-medium) {
            margin-bottom: 0;
            margin-left: 6rem;
        }
    }

    &__line{
        height: 0.2rem;
        width: 9rem;
        rotate: 90deg;
        background: #707070;
        @media only screen and (max-width: $bp-medium) {
            width: 15rem;
            rotate: initial;
         }

    }

    &__text {
        color: white;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 6rem;
        writing-mode: tb;
        letter-spacing: 1.5px;

        @media only screen and (max-width: $bp-medium) {
            writing-mode: horizontal-tb;
            margin-top: 0;
        }
    }

    //Animation
    &:nth-child(2) {
        transition-delay: 200ms;
    }
    &:nth-child(3) {
        transition-delay: 400ms;
    }
    &:nth-child(4) {
        transition-delay: 600ms;
    }
}

.text-box{
    &__paragraph {
        width: 84%;
        font-size: 1.5rem;
        color: $color-black;
        font-weight: 600;
        line-height: 1.5;
        

        @media only screen and (max-width: $bp-medium) {
            font-size: 1.8rem;
            width: 100%;
        }
    }

}