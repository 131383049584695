.btn {
    text-decoration: none;
    color: #fff;
    font-family: $font-nav;
    font-weight: 600;
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    padding: 1.4rem 3.2rem;

        @media only screen and (max-width: $bp-medium) { 
            padding: 1.5rem 2rem;
        }
        
        &__footer {
            background: #000;
            border-radius: 0 1rem 0 0;
            transition: box-shadow 0.5s;

            &:hover, &:active {
                box-shadow: -6px 5px 7px 2px rgba(0,0,0,0.2);
                -webkit-box-shadow: -6px 5px 7px 2px rgba(0,0,0,0.2);
                -moz-box-shadow: -6px 5px 7px 2px rgba(0,0,0,0.2);
            }

            
            @media only screen and (max-width: $bp-smallest) { 
                padding: 1.5rem 9rem;
                border-radius: 0;
            }

    }
    
    &--nav {
        border-radius: 0 1rem 0 0;

        @media only screen and (max-width: $bp-smallest) { 
            visibility: hidden;
        }
    }

    &--plain {
        color: $color-primary;
        transition: all ease-in .3s;
        margin-left: 1.5rem;
        background-image: linear-gradient(120deg,transparent 0,transparent 50%,#fff 50%);
        background-size: 220%;

        &:hover,
        &:active { 
            box-shadow: -6px 5px 7px 2px rgba(0,0,0,0.2);
            background-position: 100%;
        }
    }


    &__cta {
        background: $color-primary;
        transition: all ease-in .3s !important;

        &:hover,
        &:active { 
            box-shadow: -6px 5px 7px 2px #0003;
            background-color: #fff;
            color: $color-primary;
        }
    }
}

.btn__social{
    background-color: $color-primary;
    padding: 1.5rem 2.8rem;
    position: relative;
    border-radius: 2.5px;

    &--google {
        background-color: $color-primary;
        img{
            width: 23%;
            position: absolute;
            top: 3.5px;
            left: 5px;
        }

        span{
            margin-left: 3rem;
        }
    }
    
    &--whatsapp { 
        background-color: #098537;
        img{
            width: 16%;
            top: 9px;
            left: 12px;
            position: absolute;
        }

        span{
            margin-left: 1.5rem;
        }
    }
    



}
.heading-1 {
    color: #fff;
    font-size: 4rem;
    &--footer{
        font-family: 'African', sans-serif;
    }
}

.heading-2 {
    font-size: 3.5rem;

    @media only screen and (max-width: $bp-smallest) { 
        font-size: 2.5rem;
    }


    &--white {
        color: white;
    }
}

.heading-4 {
    font-size: 1.5rem;

    &--nowrap {
        white-space: nowrap;
    }
    span{
        background: $color-primary;
        color: #fff;
        padding: .5rem 0;
    }


}

.text-box__heading{
    display: flex;
    align-items: center;
}
.line {
    width: 15rem;
    height: .2rem;
    background: $color-primary;
    margin-left: 5px;

    @media only screen and (max-width: $bp-smallest) {  
        width: 13rem;
    }
}