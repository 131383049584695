.header {
    grid-column: full-start /center-end;
    display: grid;
    grid-template-rows: min-content min-content;
    

    @media only screen and (max-width: $bp-medium) { 
        gap: 5rem;
    }

    .hero-section{
        display: grid;
        grid-template-columns: 1fr minmax( min-content, 1fr);
        align-items: center;

        @media only screen and (max-width: $bp-smallest) { 
            grid-template-rows: minmax( min-content, 1fr) minmax( min-content, 1fr);
            grid-template-columns: auto
        }

        &__content{
            display: flex;
            flex-direction: column;
            gap: 1rem 0;

            @media only screen and (max-width: $bp-smallest) { 
                align-items: center;
            }
        }

        &__title{
            font-size: 5rem;
            line-height: 1.25;

            @media only screen and (max-width: $bp-medium) { 
                font-size: 4rem;
            }

            @media only screen and (max-width: $bp-smallest) { 
                text-align: center;
            }

            span {
                background-color: $color-primary;
                color: #fff;
            }
        }

        &__image img {
            width: 100%;
            height: 100%;
        }

        &__paragraph {
            @media only screen and (max-width: $bp-medium) { 
                width:66%;
            }

            @media only screen and (max-width: $bp-medium) { 
                text-align: center;
            }
            width: 57%;
            line-height: 1.5;
            letter-spacing: .5px;
            font-size: 1.2rem;
            color: $color-black;
            text-align: left;
            margin-bottom: 1.2rem;
            font-family: $font-nav;
        }
    }

    
}


.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3.2rem;

    @media only screen and (max-width: $bp-small) { 
      overflow: hidden;
    }

    &__logo {
        font-family: 'African', sans-serif;
        color: $color-black;
        font-size: 3.9rem;
        text-decoration: none;
        white-space: nowrap;
        @media only screen and (max-width: $bp-medium) { 
            font-size: 3.3rem;
        }
    }
    &__list{
        display: flex;
        align-items: center;
        gap: 5rem;
        list-style-type: none;
        @media only screen and (max-width: $bp-medium) { 
            gap: 2rem;
        }

        @media only screen and (max-width: $bp-smallest) { 
            visibility: hidden;
        }

        .nav__link {
            text-decoration: none;
            font-family: $font-nav;
            font-weight: 400;
            font-size: 1.5rem;
            color: $color-black;
            transition: all 250ms ease-in-out;
            background-image: linear-gradient(90deg, #0000 0 50%, $color-primary 50%);
            background-size: 220%;
            padding: .7rem;

            @media only screen and (max-width: $bp-medium) { 
                font-size: 1.35rem;
            }

            &:hover,
            &:active {
                color: #fff;
                background-position: 100%;
            }
            // &:after {
            //     display:block;
            //     content: '';
            //     border-bottom: solid 3px $color-secondary;  
            //     transform: scaleX(0);  
            //     transition: transform 250ms ease-in-out;
            //     margin-top: .5rem;
            // }

            // &:hover { color: $color-grey-2; }
            // &:hover:after { transform: scaleX(1); }
            // &:after{  transform-origin:  0% 50%; }
        }
    }
}

.mobile__navigation{
  @media only screen and (min-width: $bp-smallest) { 
    visibility: hidden;
  }


    &__checkbox{
     display: none;
    }
  
    &__button{
      background-color: $color-primary;
      height: 7rem;
      width: 7rem;
      border-radius: 50%;
      position: fixed;
      top:4rem;
      right: 4rem;
      z-index: 2000;
      box-shadow: 0 1rem 3rem rgba($color-black,.1);
      text-align: center;
      cursor: pointer;
  
    //   @include respond(tab-port){
    //     top: 4rem;
    //     right: 4rem;
    //   }
    //   @include respond(phone){
    //     top: 3rem;
    //     right: 3rem;
    //   }
    }
  
    &__background{
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      position: fixed;
      top: 4.5rem;
      right: 4.5rem;
      background-image: radial-gradient($color-bg,$color-secondary);
      z-index: 1000;
      transition: transform .8s cubic-bezier(0.86, 0, 0.07, 1);
      will-change: transform;
      //transform: scale(80);
    //   @include respond(tab-port){
    //     top: 4.5rem;
    //     right: 4.5rem;
    //   }
    //   @include respond(phone){
    //     top: 3.5rem;
    //     right: 3.5rem;
    //   }
    }
  
    &__nav{
      height: 100vh;
      position: fixed;
      top:0;
      left: 0;
      z-index: 1500;
      opacity: 0;
      width: 0;
      transition: all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      visibility: hidden;
      will-change: visibility, width, opacity;
    }
  
     &__list{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
       list-style-type: none;
       text-align: center;
       width: 100%;
     }
  
    &__item{
      margin: 1rem;
    }
  
    &__link{
      &:link,
      &:visited{
      display: inline-block;
      font-size: 3rem;
      font-weight: 500;
      text-decoration: none;
      color: #000;
      text-transform: uppercase;
      padding: 1rem 2rem;
      background-image: linear-gradient(120deg,transparent 0%,transparent 50%,#fff 50%);
      background-size: 220%;
      transition: all .4s;
  
        span{
          display: inline-block;
          margin-right: 1.5rem;
        }
  
      }
  
      &:hover,
      &:active{
        background-position: 100%;
        color: $color-primary;
  
        transform: translateX(1rem);
      }
  
    }
  
    //Functionality
  
    &__checkbox:checked ~ &__background {
      transform: scale(80);
    }
    &__checkbox:checked ~ &__nav {
      visibility: visible;
      width: 100%;
      opacity: 1;
    }
  
    //Icon
    &__icon{
      position: relative;
      margin-top: 3.5rem;
      &,
      &::before,
      &::after{
        width: 3rem;
        height: 2px;
        background-color: #fff;
        display: inline-block;
  
      }
  
      &::before,
      &::after{
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
      }
  
      &::before{top:-.8rem;}
      &::after{top: .8rem;}
  
    }
  
    &__button:hover &__icon::before{
      top: -1rem;
    }
    &__button:hover &__icon::after{
      top: 1rem;
    }
  
    &__checkbox:checked + &__button &__icon{
       background-color: transparent;
  
    }
    &__checkbox:checked + &__button &__icon::before{
      top: 0;
       transform: rotate(135deg);
    }
    &__checkbox:checked + &__button &__icon::after{
      top: 0;
      transform: rotate(-135deg);
    }
  
  }


