.members {
    grid-column: full-start / center-end;
    margin: 15rem 0;


    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__group {
        overflow-x: scroll;
        scroll-behavior: smooth;
        display: flex;
        column-gap: 3.5rem;

        &::-webkit-scrollbar{
            display: none;
        }

        scrollbar-width: none !important;
    }

    &__box{

        &__desc {
            font-size: 1.35rem;
            margin-top: .5rem;
        }
        &__img{
            height: 30rem;
            width: 22rem;
            object-fit: cover;
            object-position: center;
            margin-bottom: 1rem;
            box-shadow: 4px 6px 10px 0px rgba(0,0,0,0.48);
    
            img {
                width: 100%;
                height: 100%;
            }
    
        }
    }
    
}

.arrows{
    display: flex;
    gap: 1rem;
}



.next,.prev {
    width: 4rem;
    height: 4rem;
    border: 2px solid #0297D3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: $bp-smallest) { 
        visibility: hidden;
    }

    img {
        height: 2rem;
        width: 2rem;
    }
}

// .next {
//     background-color: #fff;
// }

// .prev {
//     background-color: #000
// }
.visible {
    visibility: visible !important;
}
.modal {
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color:rgba(0,0,0,0.8);
    position: fixed;
    z-index: 3000;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    overflow-x: hidden;
    overflow-y: hidden;
    backdrop-filter: blur(6px);
    visibility: hidden;
    transition: all 0.5s;
    @media only screen and (max-width: $bp-smallest) { 
        padding: 0 2rem;
    }

    &__paragraph {
        width: 57%;
        color: #fff;
        text-align: center;
        z-index: 3500;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.7;
        position: relative;
        overflow: hidden;

        @media only screen and (max-width: $bp-smallest) { 
            width: 100%;
            text-align: justify;
            font-size: 1.5rem;
        }
    }

    &__image {
        height: 9rem;
        width: 9rem;
        object-fit: fill;
        border-radius: 100%;
    }

    &__buttons{
        display: flex;
        gap: 1rem;

        @media only screen and (max-width: $bp-smallest) { 
            flex-direction: column;
            align-items: center;
        }
        
    }
}

