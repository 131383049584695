.gallery{
    grid-column: sidebar-start / span 11;
    margin: 15rem 0;

    &__groups {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(12, 10vw);
        grid-gap: 2rem;

        @media only screen and (max-width: $bp-medium) { 
            grid-gap: .5rem;
        }

        @media only screen and (max-width: $bp-smallest) { 
            grid-gap: 0rem;
        }

        
    }
    &-res{
        @media only screen and (max-width: $bp-smallest) { 
            margin-left: 3rem !important;
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        display: block;
    }
    
    &__item {
        opacity: 0;
        transition: all 2s;

        @media(prefers-reduced-motion) {
            transition: none;
        }

        &--1{
            grid-column: 1/span 2;
            grid-row: 1/span 2;
            
        }

        &--2{
            grid-area: 1 / 3 / span 5 / span 2;
            transition-delay: 200ms;
        }

        &--3{ 
            grid-area: 1 / 5 / span 3 / span 2;
            transition-delay: 300ms;
        }

        &--4{
            grid-area: 3 / 1 / span 4 / span 2;
            transition-delay: 400ms;
        }

        &--5{ 
            grid-area: 7 / 1 / span 2 / span 2;
            transition-delay: 500ms;
        }

        &--6{
            grid-area: 6 / 3 / span 3 / span 2;
            transition-delay: 600ms;
        }

        &--7{
            grid-area: 4 / 5 / span 2 / span 2;
            transition-delay: 700ms;
        }

        &--8{
            grid-area: 6 / 5 / span 3 / span 2;
            transition-delay: 800ms;
        }
        &--9{
            grid-area: 9 / 1 / span 3 / span 4;
            transition-delay: 900ms;
        }

        &--10{
            grid-area: 9 / 5 / span 4 / span 2;
            transition-delay: 1000ms;
        }
    }

}

.show{
    opacity: 1;
}