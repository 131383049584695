.mission {
    grid-column: full-start / center-end;
    margin: 15rem 0;
    background: $color-primary;
    padding: 6.5rem 10rem;
    border-radius: 1rem;
    position: relative;
    background-image: $bg-gradient;

    img{
        position: absolute;
        top: -23%;
        left: -3%;
        height: 100%;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 10;
    }

    &__paragraph { 
        width: 48%;
        text-align: center;
        font-size: 1.35rem;
        line-height: 1.7;
        font-weight: 600;
        color: #fff;
        

        @media only screen and (max-width: $bp-medium) { 
            width: 90%;
            font-size: 1.5rem;
        }
        
        @media only screen and (max-width: $bp-smallest) { 
            width: 100%;
            text-align: justify;
            font-size: 1.3rem;
        }
    }


}
